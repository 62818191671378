<script>

export default {
    name: "CookiesPage",
    components: {

    },
    props: ['templateMode'],
    created(){
         this.$emit('headerState', 1);
    },
    methods: {
        

    }
};
</script>

<style lang="scss">
    @import "cookies-page.scss";
</style>

<template src="./cookies-page.html"></template>